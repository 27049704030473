import chunkCreate from './chunkCreate'
import distractors from './distractors'
import heading from './heading'
import rephrase from './rephrase'
import table2text from './table2text'
import quiz from './quiz'
import quizBasedQA from './quizBasedQA'
import question_answer from './questionAnswer'


const prompts = {
  chunkCreate,
  distractors,
  heading,
  rephrase,
  table2text,
  quiz,
  quizBasedQA,
  question_answer
}

export default prompts
