<script>
import Vue from 'vue'

import GeneratorCardMixin from '@/mixins/GeneratorCardMixin'
import DocumentMixin from '@/mixins/DocumentMixin'

import ModalEditQuest from '../modals/EditQuest.vue'
import { mapGetters } from 'vuex'
import BtnGenerator from '@/components/BtnGenerator.vue'

export default {
  name: 'GeneratorQA',
  components: { BtnGenerator, ModalEditQuest },
  mixins: [GeneratorCardMixin, DocumentMixin],
  data() {
    return {}
  },
  computed: {
    ...mapGetters(['HAS_PERMISSION']),
    calcTitle() {
      const t = ['Генератор вопросы-ответы']
      if (this.fromChunkData) {
        t.push(`для чанка "${ this.fromChunkData.chunkTitle }"`)
      }
      return t.join(' ')
    }
  },
  mounted() {
    // fixme убрать предзаполнение
    this.documentLink = 'https://library.mededtech.ru/rest/documents/KP654/index.html#paragraph_9njhu1'
  },
  methods: {
    onGenerate(data) {
      this.result = data
    },
    createQuestInChunk(quest) {
      if (!this.fromChunkData) {
        this.$bvToast.toast('Вы не в чанке', { ...this.commonToaster, variant: 'warning' })
      }

      Vue.set(this.edit, 'editQuest', { question: quest.question, answer: quest.answers[0].text })
      Vue.set(this.edit, 'chunk', { id: this.fromChunkData.chunkId, title: this.fromChunkData.chunkTitle })
      Vue.set(this.edit, 'questions', [])
      Vue.set(this.edit, 'questionType', 'question_answer')
      this.$bvModal.show('modal-edit-quest')
    }
  },
  watch: {}
}
</script>

<template>
  <b-container fluid>
    <modal-edit-quest :edit="edit"/>
    <b-row class="mt-3">
      <b-col cols="12" md="6">
        <b-card :title="calcTitle">
          <b-card-text>
            <b-card class="mb-1" no-body>
              <b-card-header class="p-1" header-tag="header" role="tab">
                <b-button v-if="documentParams.name" v-b-toggle.accordion-1 block variant="secondary">
                  {{ documentParams.name }}
                </b-button>
                <template v-else>Ссылка на документ</template>
              </b-card-header>
              <b-collapse id="accordion-1" :visible="linkShow" accordion="my-accordion" role="tabpanel">
                <b-card-body>
                  <b-input-group>
                    <b-input
                        v-model="documentLink"
                        placeholder="Вставьте ссылку на параграф в библиотеке в виде https://library.mededtech.ru/rest/documents/134_2020/#paragraph_ps0nr2"
                    />
                    <b-input-group-append>
                      <b-button variant="secondary" @click="documentLink = ''">
                        <font-awesome-icon icon="xmark"/>
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                  <b-btn :disabled="running || !documentLink" icon variant="primary" @click="clickLoadDataFromLink">
                    <font-awesome-icon icon="share-from-square"/>
                    Загрузить данные из документа по ссылке
                  </b-btn>
                </b-card-body>
              </b-collapse>
            </b-card>

            <template v-if="documentParams.name">
              <!--<b-tabs v-show="documentParams.name" content-class="mt-3">-->
              <!--  <b-tab active title="Параметры">-->

              <b-form-group>
                <b-button-group>
                  <btn-generator
                      :callback="setText"
                      :params="{text: params.text}"
                      btn-text="Таблицу в текст"
                      prompt-name="table2text"
                  />
                  <btn-generator
                      :callback="setText"
                      :params="{text: params.text}"
                      btn-text="Парафраз"
                      prompt-name="rephrase"
                  />
                  <b-btn variant="outline-dark" @click="clickRevealAbbrev">
                    Аббревиатуры
                  </b-btn>
                </b-button-group>
              </b-form-group>

              <b-input-group class="mb-3">
                <b-input-group-prepend is-text>
                  Текст для генерации
                </b-input-group-prepend>

                <b-form-textarea v-model="params.text" :state="params.text > '' ? null : false" rows="8" trim/>

              </b-input-group>
              <!--</b-tab>-->

              <!--<prompt-tab :generation-params="generationParams" :messages="messages"/>-->
              <!--</b-tabs>-->
            </template>
          </b-card-text>
          <b-card-footer>
            <template v-if="documentParams.name && params.text > ''">
              <btn-generator
                  :callback="onGenerate"
                  :params="{...params, chunkText: params.text, documentName: documentParams.name}"
                  btn-variant="outline-primary"
                  prompt-name="question_answer"
              />

            </template>
          </b-card-footer>
        </b-card>
      </b-col>

      <b-col cols="12" md="6">
        <b-card>
          <b-card-text>
            <h4>
              Результат
              <!--<stopwatch ref="stopwatch" style="float: right;"/>-->
            </h4>
            <template v-if="result.questions">
              <!--<h5>{{ result.data[0].title }}</h5>-->
              <b-card v-for="(quest, questIndex) in result.questions"
                      :key="questIndex"
                      class="question mb-2">
                <template #header>
                  <span>
                    {{ questIndex + 1 }}. {{ quest.question }}
                  </span>
                  <b-button-group v-if="fromChunkData && fromChunkData.chunkId && HAS_PERMISSION('corpus_quest_edit')"
                                  size="sm">
                    <b-btn title="Скопировать вопрос в чанк"
                           variant="outline-secondary" @click="createQuestInChunk(quest)">
                      <font-awesome-icon
                          fixed-width
                          icon="clone"
                      />
                    </b-btn>
                  </b-button-group>
                </template>
                <div class="ml-2">
                  {{ quest.answer }}
                </div>
              </b-card>

            </template>

          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<style scoped>

.card-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
</style>