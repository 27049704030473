import { AiAlias, Prompt } from './main'

const systemContent = ``
const userContent = `Я предложу тебе текст в виде текстовой таблицы, а тебе нужно эту таблицу преобразовать в обычный, логично связанный текст.
Вот моя текстовая таблица: "{text}".
Подумай и приступай`
export default new Prompt({
  messages: {
    system: systemContent,
    user: userContent
  },
  model: AiAlias.ALT,
  params: {
    temperature: 0
  }
})
