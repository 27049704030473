import { klona } from 'klona'
import { formatUnicorn } from '@/utils/formatters'

export const AiAlias = {
  STD: 'std',
  MINI: 'mini',
  PRO: 'pro',
  ALT: 'alt',
}

export class Prompt {

  constructor ({ messages, model = AiAlias.MINI, params }) {
    this.messages = klona(messages)
    this.messagesCached = klona(this.messages)
    this.model = model
    this.params = {
      temperature: 0.7,
      ...(params ?? {})
    }
  }

  mapVars (vars) {
    this.messages = klona(this.messagesCached)
    if (!vars) return
    this.messages.system = formatUnicorn(this.messages.system, vars)
    this.messages.user = formatUnicorn(this.messages.user, vars)
  }

  previewVars (vars) {
    const messages = klona(this.messagesCached)
    messages.system = formatUnicorn(this.messages.system, vars)
    messages.user = formatUnicorn(this.messages.user, vars)
    return messages
  }
}
