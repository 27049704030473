<script>
import DocumentMixin from '@/mixins/DocumentMixin'
import api from '@/api'
import Vue from 'vue'
import CardMixin from '@/mixins/CardMixin'
import { mapGetters } from 'vuex'
import DocumentAbbrevs from '@/components/DocumentAbbrevs.vue'
import BtnGenerator from '@/components/BtnGenerator.vue'

export default {
  mixins: [DocumentMixin, CardMixin],
  components: { BtnGenerator, DocumentAbbrevs },
  props: {
    edit: Object
  },
  data() {
    return {
      isNew: false,
      formData: {
        title: '',
        text: '',
        link: '',
        kind: null
      }
    }
  },
  methods: {
    onShow() {
      if (!this.edit.editChunk.id) {
        this.isNew = true
        return
      }
      this.isNew = false

      this.formData.title = this.edit.editChunk.title
      this.formData.text = this.edit.editChunk.text
      this.formData.kind = this.edit.editChunk.kind
      this.formData.link = this.$parent.calcChunkLink(this.edit.editChunk, this.$parent.selectedSource)
    },
    generatorTextHandler(text) {
      this.formData.text = text
    },
    clickRevealAbbrev() {
      this.revealAbbrev(this.$parent.selectedSource.document_id, this.formData.text)
          .then(response => {
            this.generatorTextHandler(response)
          })
    },
    async apiCall() {
      if (!this.isNew) {
        // Редактирование чанка
        return api.post(`/corpus/chunk/${ this.edit.editChunk.id }`, {
          ...this.formData,
          anchor: this.urlAnchor
        })
            .then(({ data }) => {
              Vue.set(this.edit.editChunk, 'title', data.title)
              Vue.set(this.edit.editChunk, 'text', data.text)
              Vue.set(this.edit.editChunk, 'anchor', data.anchor)
              Vue.set(this.edit.editChunk, 'kind', data.kind)
              this.$bvToast.toast(
                  'Чанк сохранен', { ...this.commonToaster, variant: 'success' }
              )
              this.$bvModal.hide('modal-edit-chunk')
              this.$parent.eraseSelection()
            })
            .catch(error => {
              console.log(error)
              this.$bvToast.toast('Какая-то ошибка', {
                ...this.commonToaster, variant: 'danger'
              })
            })
      }
      else {
        // Создание чанка
        return api.post(`/corpus/section/${ this.edit.section.id }/chunk`, {
          title: this.formData.title,
          text: this.formData.text,
          kind: this.formData.kind,
          anchor: this.urlAnchor
        })
            .then(({ data }) => {
              this.edit.section.chunks.push(data)
              this.$bvToast.toast(
                  'Чанк создан', { ...this.commonToaster, variant: 'success' }
              )
              this.$bvModal.hide('modal-edit-chunk')
            })
            .catch(error => {
              console.log(error)
              this.$bvToast.toast('Какая-то ошибка', {
                ...this.commonToaster, variant: 'danger'
              })
            })
      }
    },
    clear() {
      this.formData.title = this.formData.text = this.formData.link = ''
      this.formData.kind = null
      delete this.edit.editChunk
      delete this.edit.section
    }
  },
  computed: {
    ...mapGetters(['HAS_PERMISSION', 'CHUNK_ICONS']),
    cardTitle() {
      if (!this.isNew) {
        return `Редактирование чанка "${ this.edit?.editChunk?.title || 'Без заголовка' }"`
      }
      else {
        return `Создание чанка в разделе "${ this.edit?.section?.title }"`
      }
    },
    stateText() {
      return this.formData.text.length >= 10
    },
    urlAnchor() {
      return this.parseLink(this.formData.link).anchor
    },
    stateLink() {
      if (!this.$parent.selectedSource) {
        return true
      }
      const docUrl = `${ process.env.VUE_APP_DOCUMENTS_ROOT }/${ this.$parent.selectedSource?.document_id }`
      if (!this.formData.link.startsWith(docUrl)) {
        return false
      }
      return !!this.urlAnchor

    }
  }
}
</script>

<template>
  <b-modal
      id="modal-edit-chunk"
      :busy="!(stateText && stateLink) || loading"
      :title="cardTitle" centered lazy no-close-on-backdrop ok-only scrollable
      size="lg"
      static
      @close="clear"
      @show="onShow"
      @ok.prevent="save"
  >

    <b-form-group label="Заголовок">
      <b-input v-model="formData.title" trim/>
    </b-form-group>

    <b-form-group label="Текст">
      <b-textarea v-model="formData.text" :state="stateText" rows="5" trim/>
      <b-button-group>
        <btn-generator
            :callback="generatorTextHandler"
            :disabled="loading"
            :params="{text: formData.text}"
            btn-text="Таблицу в текст"
            prompt-name="table2text"
        />
        <btn-generator
            :callback="generatorTextHandler"
            :disabled="loading"
            :params="{text: formData.text}"
            btn-text="Парафраз"
            prompt-name="rephrase"
        />
        <b-btn :disabled="loading" variant="outline-dark" @click="clickRevealAbbrev">Замена аббревиатур</b-btn>
      </b-button-group>
    </b-form-group>

    <b-form-group label="Ссылка">
      <b-input-group>
        <b-textarea id="modal-edit-par-link" v-model="formData.link" :state="stateLink" trim/>
        <b-input-group-append>
          <b-btn :href="formData.link" target="_blank">
            <font-awesome-icon icon="arrow-up-right-from-square"/>
          </b-btn>
        </b-input-group-append>

        <b-form-invalid-feedback v-if="$parent.selectedSource" id="edit-par-link-feedback">
          Ссылка должна вести на документ <i>"{{ $parent.selectedSource.title }}"</i> и ссылаться на конкретный
          параграф
        </b-form-invalid-feedback>

      </b-input-group>
    </b-form-group>

    <b-input-group label="Тип чанка">
      <b-input-group-prepend v-if="formData.kind" is-text>
        <font-awesome-icon :icon="CHUNK_ICONS[formData.kind].icon"/>
      </b-input-group-prepend>

      <b-select v-model="formData.kind">
        <option v-for="(option, key) in CHUNK_ICONS" :value="key">
          {{ option.tooltip }}
        </option>
      </b-select>

      <b-input-group-append>
        <b-btn :disabled="loading" @click="formData.kind = null">
          <font-awesome-icon icon="xmark"/>
        </b-btn>
      </b-input-group-append>
    </b-input-group>

    <template #modal-footer="{ ok }">
      <!-- Emulate built in modal footer ok and cancel button actions -->
      <document-abbrevs :doc-id="$parent.selectedSource.document_id" title="Показать аббревиатуры документа"/>
      <b-button variant="primary" @click="ok()">
        Сохранить
      </b-button>
    </template>
  </b-modal>
</template>

<style scoped>

</style>