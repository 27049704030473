<script>
import GeneratorMixin from '@/mixins/GeneratorMixin'
import BtnGenerator from '@/components/BtnGenerator.vue'

export default {
  name: 'DistractorSearch',
  components: { BtnGenerator },
  props: {
    theme: {
      type: String,
      default: ''
    }
  },
  mixins: [GeneratorMixin],
  data() {
    return {
      showDistractor: '',
      params: {
        text: '',
        theme: ''
      }
    }
  },
  methods: {},
  watch: {
    theme(value) {
      this.params.theme = value
    }
  }
}
</script>

<template>
  <b-container fluid style="padding: 0">
    <b-row no-gutters>
      <b-col>
        <h4>
          Поиск дистракторов
          <stopwatch ref="stopwatch" style="float: right;"/>
        </h4>
      </b-col>
    </b-row>
    <b-row class="mb-2" no-gutters>
      <b-col class="pr-2" cols="6">
        <b-input-group>
          <b-input-group-prepend is-text>
            Тема
          </b-input-group-prepend>

          <b-form-input v-model="params.theme"/>
        </b-input-group>
      </b-col>
      <b-col cols="6">
        <b-input-group>
          <b-input-group-prepend is-text>
            Верный ответ
          </b-input-group-prepend>

          <b-form-input v-model="params.text"/>
          <b-input-group-append>
            <btn-generator
                :callback="(data) => messages.assistant = data"
                :params="params"
                btn-text="Найти"
                prompt-name="distractors"
            />

          </b-input-group-append>
        </b-input-group>
      </b-col>
    </b-row>
    <b-row no-gutters>
      <b-textarea :value="messages.assistant" max-rows="25" rows="5"/>
    </b-row>

  </b-container>
</template>

<style scoped>

</style>