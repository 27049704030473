import GeneratorMixin from '@/mixins/GeneratorMixin'
import UrlMixin from '@/mixins/UrlMixin'

export default {
  mixins: [GeneratorMixin],
  data () {
    return {
      linkShow: true,
      result: {},
      fromChunkData: undefined,
      edit: {}
    }
  },
  watch: {
    'messages.assistant' (value) {
      if (value) {
        this.result = value //JSON.parse(value)
      }
    }
  },
  mounted () {
    if (this.$route.query.chunk) {
      this.fromChunkData = UrlMixin.decode(this.$route.query.chunk)
      this.fromUrlParams(this.fromChunkData)
      this.params.text = this.fromChunkData.chunkText
      this.loadDocumentData().then()
      this.linkShow = false
    }
  },
  methods: {
    setText (value) {
      this.params.text = value.strip()
    },
    clickLoadDataFromLink () {
      if (!this.documentLink) {
        return
      }
      this.loadDocumentData()
        .then(data => {
          this.params.text = data.text
          this.linkShow = false

          if (this.documentParams.specList.length === 0) {
            this.$bvToast.toast(`Работа с этим документом невозможна: отсутствуют специальности`, {
              ...this.commonToaster, variant: 'danger'
            })
          }
        })
    },
    clickRevealAbbrev () {
      this.revealAbbrev(this.documentParams.id, this.params.text)
        .then(value => {
          if (!value) return
          this.setText(value)
        })
    },
    fromUrlParams (params) {
      this.documentParams.id = params.docId
      this.documentParams.anchor = params.anchor
      this.documentLink = `${ process.env.VUE_APP_DOCUMENTS_ROOT }/${ params.docId }/#${ params.anchor }`
      this.params.subDiscipline = params.chunkTitle
    }
  }
}
