<script>
import api from '@/api'
import DocumentMixin from '@/mixins/DocumentMixin'
import CorpusMixin from '@/mixins/CorpusMixin'
import Vue from 'vue'
import { mapGetters } from 'vuex'

export default {
  name: 'CorpusRoot',
  data() {
    return {
      corpusList: {
        loading: false,
        items: []
      },
      specList: [],
      filter: undefined,
      options: {
        currentPage: 1,
        perPage: 20,
        pageOptions: [20, 50, 100],
        sortBy: 'id',
        sortDesc: false,
        sortDirection: 'asc'
      },
      edit: {
        enabled: false,
        item: {
          name: '',
          year: 2020,
          speciality_data: {}
        }
      }
    }
  },
  mixins: [DocumentMixin, CorpusMixin],
  computed: {
    ...mapGetters(['CORPUS_LIST', 'HAS_PERMISSION']),
    itemValid() {
      return !this.edit.enabled || (this.edit.item.name > '')
    },
    tableFields() {
      const res = [
        { key: 'id', label: 'Id', sortable: true },
        { key: 'name', label: 'Название', sortable: true },
        { key: 'year', label: 'Год', sortable: true },
        { key: 'speciality_data.name', label: 'Специальность', sortable: true },
        { key: 'last_modified.date', label: 'Последнее редактирование', sortable: true, formatter: this.formatDate }
      ]
      if (this.HAS_PERMISSION('corpus_edit')) {
        res.push({ key: 'actions', label: 'Действия', sortable: false })
      }
      return res
    }
  },
  methods: {
    clickItemEdit(item) {
      this.edit.enabled = true
      if (!item.id) {
        item = {
          name: '',
          year: 2020,
          speciality_data: {}
        }
        this.corpusList.items.push(item)
      }
      Vue.set(this.edit, 'item', item)
    },
    clearEdit() {
      this.edit.enabled = false
      if (!this.edit.item.id) {
        this.corpusList.items.splice(-1)
      }
      Vue.set(this.edit, 'item', { name: '' })
    },
    callDeleteCorpus(item) {
      this.deleteCorpus(item).then(() => {
        this.loadCorpusList()
      })
    },
    loadCorpusList() {
      this.corpusList.loading = true
      this.$store.dispatch('CORPUS_LOAD_LIST')
          .then(() => {
            this.corpusList.items = this.CORPUS_LIST
          })
          .catch(({ response }) => {
            if (response.status === 401) {
              return this.$router.push({ path: '/' })
            }
            this.$bvToast.toast(
                'Неизвестная ошибка при загрузке списка корпусов', {
                  ...this.commonToaster, variant: 'danger'
                }
            )
            console.log(response)
          })
          .finally(() => this.corpusList.loading = false)
    },
    callUpdateCorpus(item) {
      if (item.id !== this.edit.item.id) {
        this.$bvToast.toast(
            'Сбой в редактировании корпуса. Попробуйте обновить страницу',
            { variant: 'danger', ...this.commonToaster }
        )
      }
      if (this.edit.item.id) {
        this.updateCorpus(this.edit.item).then(() => this.clearEdit())
      }
      else {
        api.post('/corpus', this.edit.item)
            .then(({ data }) => {
              this.corpusList.items.splice(-1)
              this.corpusList.items.push(data)
            })
            .catch(({ response }) => {
              if (response.handled) return

              if (response.status === 423) {
                return this.$bvToast.toast(
                    'Корпус с таким именем уже существует',
                    { ...this.commonToaster, variant: 'danger' }
                )
              }

              this.$bvToast.toast(
                  'Неизвестная ошибка при создании корпуса',
                  { ...this.commonToaster, variant: 'danger' }
              )
              console.log(response)
            })
      }
    },
    goToCorpus(corpusId) {
      this.$router.push({ name: 'CorpusEdit', params: { corpusId } })
    }
  },
  mounted() {
    this.loadCorpusList()
    this.loadSpecList()
        .then(res => this.specList = res)
        .catch((error) => console.log(error))
  }
}
</script>

<template>
  <b-card title="Корпусы">
    <b-card-text>
      <b-button-toolbar class="button_toolbar">
        <b-button-group class="mx-1">
          <b-btn v-if="HAS_PERMISSION('corpus_edit')" variant="outline-primary" @click="clickItemEdit">
            <font-awesome-icon icon="plus"/>
            Создать новый
          </b-btn>
          <b-btn variant="outline-primary" @click="loadCorpusList">
            <font-awesome-icon icon="arrows-rotate"/>
            Обновить
          </b-btn>
        </b-button-group>
      </b-button-toolbar>

      <div class="float-right">
        <b-form-select v-model="options.perPage"
                       :options="options.pageOptions"
                       :select-size="1"
                       class="page-select">
        </b-form-select>
        <div class="page-per float-right">По:</div>
      </div>

      <b-pagination v-model="options.currentPage"
                    :per-page="options.perPage"
                    :total-rows="corpusList.items.length"
                    class="float-right mx-0 my-0"></b-pagination>
      <div class="page-total float-right">Всего: {{ corpusList.items.length }}</div>
      <b-input-group class="float-right w-25 mr-2">
        <b-form-input v-model="filter" placeholder="Поиск по таблице"></b-form-input>
        <b-input-group-append>
          <b-btn :disabled="!filter" class="page-filter-clear" @click="filter = undefined">
            <font-awesome-icon icon="eraser"/>
          </b-btn>
        </b-input-group-append>
      </b-input-group>

      <b-table
          :busy.sync="corpusList.loading"
          :current-page="options.currentPage"
          :fields="tableFields"
          :filter="filter"
          :items="corpusList.items"
          :per-page="options.perPage"
          :sort-by.sync="options.sortBy"
          :sort-desc.sync="options.sortDesc"
          hover
          responsive
          show-empty
          small
          striped
          @row-dblclicked="(item) => goToCorpus(item.id)"
      >
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle"/>
            <strong>Идёт загрузка...</strong>
          </div>
        </template>
        <template #cell(name)="{ item, value }">
          <b-form-input v-if="edit.item.id === item.id" v-model="edit.item.name" :state="edit.item.name > ''" autofocus
                        trim/>
          <template v-else>
            {{ value }}
          </template>
        </template>
        <template #cell(year)="{ item, value }">
          <b-form-input v-if="edit.item.id === item.id" v-model="edit.item.year"
                        :state="edit.item.year > 2000 && edit.item.year <= new Date().getFullYear()"
                        trim type="number"/>
          <template v-else>
            {{ value }}
          </template>
        </template>
        <template #cell(speciality_data.name)="{ item }">
          <b-form-select
              v-if="edit.item.id === item.id"
              v-model="edit.item.speciality_data"
          >
            <option v-for="speciality in specList" :value="{name: speciality.name, code: speciality.code}">
              {{ speciality.text }}
            </option>
          </b-form-select>
          <template v-else-if="item.speciality_data.code">
            {{ item.speciality_data.name }} {{ item.speciality_data.code }}
          </template>
        </template>
        <template #cell(actions)="{item, index}">
          <b-button-group size="sm">
            <template v-if="edit.item.id !== item.id">
              <b-btn title="Редактировать корпус" @click="clickItemEdit(item, index)">
                <font-awesome-icon fixed-width icon="pen"/>
              </b-btn>
              <b-btn title="Удалить корпус" variant="danger" @click="callDeleteCorpus(item, index)">
                <font-awesome-icon fixed-width icon="trash"/>
              </b-btn>
            </template>
            <template v-else>
              <b-btn :disabled="!itemValid"
                     :variant="itemValid ? 'success' : 'danger'"
                     @click="callUpdateCorpus(item)">
                <font-awesome-icon :icon="['far', 'floppy-disk']" fixed-width/>
              </b-btn>
              <b-btn @click="clearEdit">
                <font-awesome-icon fixed-width icon="times" title="Отменить изменения"/>
              </b-btn>
            </template>

          </b-button-group>
        </template>
      </b-table>
    </b-card-text>
  </b-card>
</template>

<style scoped>

</style>