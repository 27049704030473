<template>
  <div id="app">

    <!--Шапка-->
    <b-navbar id="appHeader" type="dark" variant="info" toggleable="xl"
              :style="`background-color: ${headerColor} !important;`">

      <b-navbar-toggle target="nav_collapse"/>

      <b-navbar-brand href="/">
        <a href="https://fmza.ru" target="_blank">
          <img class="logo" alt="logo" src="/logo.png">
        </a>
        Библиотека Аккредитации
      </b-navbar-brand>

      <b-collapse is-nav id="nav_collapse">
        <b-navbar-nav>
          <b-nav-item :to="{ name: 'Documents'}">Документы</b-nav-item>
          <b-nav-item :to="{ name: 'Methodical'}">Методические материалы</b-nav-item>
          <b-nav-item :to="{ name: 'MKB'}">МКБ-10</b-nav-item>
          <b-nav-item :to="{ name: 'Mesh'}">MeSH</b-nav-item>
          <b-nav-item href="https://selftest.mededtech.ru/" target="_blank">Репетиционный экзамен</b-nav-item>
        </b-navbar-nav>

        <b-navbar-nav class="ml-auto">

          <b-nav-item :to="{ name: 'CorpusRoot'}" v-if="HAS_PERMISSION('corpus_read')">Корпусы</b-nav-item>

          <b-nav-item-dropdown right v-if="HAS_PERMISSION('generators')" text="Генераторы">

            <b-dropdown-item :to="{ name: 'GeneratorQuiz'}">Тесты</b-dropdown-item>

            <b-dropdown-item :to="{ name: 'GeneratorQA'}">Вопросы-ответы</b-dropdown-item>
          </b-nav-item-dropdown>

          <b-nav-item-dropdown right v-if="HAS_PERMISSION('manage_users')" text="Администрирование">
            <b-dropdown-item v-if="HAS_PERMISSION('manage_users')" :to="{ name: 'ManageUsers'}">
              <font-awesome-icon icon="users"/>
              Пользователи
            </b-dropdown-item>
            <b-dropdown-item v-if="HAS_PERMISSION('generators_prompt')" :to="{ name: 'AiHistory'}">
              <font-awesome-icon icon="microchip"/>
              История AI
            </b-dropdown-item>
          </b-nav-item-dropdown>

          <b-nav-item-dropdown right v-if="IS_LOGGED_IN">
            <template #button-content>
              <em>
                <font-awesome-icon icon="user"/>
                {{ USER.fullname }}
              </em>
            </template>
            <b-dropdown-item :to="{ name: 'UserProfile'}">
              <font-awesome-icon icon="user"/>
              Профиль
            </b-dropdown-item>
            <b-dropdown-item @click="logout">
              <font-awesome-icon :icon="['fas', 'right-from-bracket']"/>
              Выход
            </b-dropdown-item>
          </b-nav-item-dropdown>

          <b-navbar-nav right v-if="!IS_LOGGED_IN">
            <b-nav-item :to="{ name: 'Login'}">Войти</b-nav-item>
          </b-navbar-nav>

        </b-navbar-nav>
      </b-collapse>
    </b-navbar>

    <div id="spinner"></div>

    <router-view/>

    <b-button title="НАВЕРХ" @click="scrollTop" id="upButton" class="fixed-bottom" v-show="showUpButton">
      <font-awesome-icon icon="arrow-up"/>
      Наверх
    </b-button>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'App',
  data() {
    return {
      headerColor: process.env.NODE_ENV === 'production' ? '#0054a6' : '#450d50',
      context: {},
      showUpButton: false
    }
  },
  mounted() {
    window.addEventListener('scroll', this.onScroll)
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.onScroll)
  },
  computed: {
    ...mapGetters(['USER', 'HAS_PERMISSION', 'IS_LOGGED_IN'])
  },
  methods: {
    onScroll() {
      // Get the current scroll position
      const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop    // Because of momentum scrolling on mobiles, we shouldn't continue if it is less than zero
      this.showUpButton = currentScrollPosition > 200

    },
    scrollTop() {
      window.scrollTo(0, 0)
    },
    smoothJumpUp() {
      if (document.body.scrollTop > 0 || document.documentElement.scrollTop > 0) {
        window.scrollBy(0, -200)
        setTimeout(this.smoothJumpUp, 20)
      }
    },
    logout() {
      this.$store.dispatch('LOGOUT')
    }
  }

}
</script>

<style scoped>
img.logo {
  height: 48px;
}

#upButton {
  left: auto;
  margin: 1rem;
  background-color: var(--orange);
  font-weight: bolder;
  border: none;
}

#spinner {
  top: 50%;
  z-index: 10000000;
  position: fixed;
  left: 50%;
}

.navbar-brand {
  padding-bottom: 0;
}

#appHeader .router-link-active {
  font-weight: bolder;
}

</style>