import { AiAlias, Prompt } from './main'
import json_schema from './questionAnswerSchema.json'

const systemContent = ``
const userContent = `Для текста, который я приведу ниже, тебе нужно создать все возможные пары вопрос-ответ таким образом, чтобы вопрос точно отражал основную тему или информацию, изложенную в тексте, а ответ был конкретным и информативным. 

Вот мой текст: "{chunkText}"`
export default new Prompt({
  messages: {
    system: systemContent,
    user: userContent
  },
  model: AiAlias.STD,
  params: {
    response_format: {
      type: 'json_schema',
      json_schema
    }
  }
})
