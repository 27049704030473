import { AiAlias, Prompt } from './main'

const systemContent = ``
const userContent = `Я дам тебе короткую фразу. Тебе нужно подобрать все возможные антонимы к этой фразе и расположить их по убыванию похожести на эту фразу. Антонимы должны быть в рамках темы для антонимов, которую я тебе дам.
Вот моя фраза: "{text}".
Вот тема для антонимов: "{theme}".
Подумай и приступай.`
export default new Prompt({
  messages: {
    system: systemContent,
    user: userContent,
  },
  model: AiAlias.MINI,
})
