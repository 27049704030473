var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-modal',{attrs:{"id":"modal-generate-chunks","cancel-title":"Исключить исключенные","centered":"","lazy":"","modal-class":"modal-fullscreen","no-close-on-backdrop":"","scrollable":"","static":"","title":"Генерация чанков"},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('b-btn',{attrs:{"variant":"secondary"},on:{"click":function($event){return _vm.$emit('unselect-excluded')}}},[_vm._v(" Исключить исключенные ")]),_c('b-btn',{attrs:{"disabled":_vm.result.length === 0 || _vm.saveLoading,"variant":"primary"},on:{"click":_vm.onSave}},[_vm._v(" Сохранить ")])]},proxy:true}])},[_c('b-input-group',{staticClass:"mb-1"},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_vm._v(" Атрибутивная информация ")]),_c('b-textarea',{model:{value:(_vm.params.info),callback:function ($$v) {_vm.$set(_vm.params, "info", $$v)},expression:"params.info"}})],1),_c('div',{staticClass:"d-flex w-100 mb-2 justify-content-between"},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('div',{staticClass:"mr-3"},[_c('b-input-group',{staticStyle:{"width":"350px"}},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_vm._v(" Символов в коротком списке ")]),_c('b-input',{attrs:{"type":"number"},model:{value:(_vm.params.smallListLength),callback:function ($$v) {_vm.$set(_vm.params, "smallListLength", $$v)},expression:"params.smallListLength"}})],1)],1),_c('div',[_c('b-input-group',{staticStyle:{"width":"250px"}},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_vm._v(" Размер чанка ")]),_c('b-input',{model:{value:(_vm.params.chunkSize),callback:function ($$v) {_vm.$set(_vm.params, "chunkSize", $$v)},expression:"params.chunkSize"}})],1)],1)]),_c('btn-generator',{attrs:{"callback":(x) => _vm.onRequestComplete(x),"params":{
          ..._vm.params,
          chunkData: _vm.chunkData,
          smallListLengthTitlePlural: _vm.pluralize(_vm.params.smallListLength, 'символ', 'символа', 'символов')
        },"prompt-name":"chunkCreate"}})],1),_c('b-row',[_c('b-col',[_c('b-card',{attrs:{"title":"Входящие чанки"}},_vm._l((_vm.selectedChunks),function(chunk){return _c('b-list-group-item',{staticClass:"flex-column align-items-start mb-1",class:{
              'excluded': chunk.is_excluded
            }},[_c('div',{staticClass:"d-flex w-100 justify-content-between title"},[_c('h5',{staticClass:"mb-1"},[_vm._v(_vm._s(chunk.title || 'Заголовок отсутствует'))])]),_c('div',[_c('chunk-text',{attrs:{"chunk":chunk,"source":_vm.source}})],1)])}),1)],1),_c('b-col',[_c('b-card',{attrs:{"title":"Результат"}},_vm._l((_vm.result),function(chunk,index){return _c('b-card',{key:`out-${index}`,staticClass:"mt-2"},[_c('div',[_c('chunk-text',{attrs:{"chunk":chunk,"source":_vm.source}})],1)])}),1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }