<script>
import SetPassword from '@/components/SetPassword.vue'
import api from '@/api'
import ModalEditUser from '@/pages/Admin/ModalEditUser.vue'
import Vue from 'vue'

export default {
  name: "ManageUsers",
  components: { SetPassword, ModalEditUser },
  data() {
    return {
      filter: undefined,
      tableData: {
        items: [],
        headers: [
          {
            key: 'actions',
            label: '',
            sortable: false
          },
          {
            key: 'email',
            label: 'Email',
            sortable: true
          },
          {
            key: 'fullname',
            label: 'ФИО',
            sortable: true
          },
          {
            key: 'permissions',
            label: 'Разрешения',
            sortable: false,
            thStyle: { width: "50%" }
          }
        ],
        loading: true,
        options: {
          currentPage: 1,
          perPage: 20,
          pageOptions: [20, 50, 100],
          sortBy: 'fullname',
          sortDesc: false,
          sortDirection: 'asc'
        }
      },
      edit: {},
      catalogData: {
        permissions: []
      }
    }
  },
  mounted() {
    this.loadUsers()
    this.loadPermissions()
  },
  methods: {
    async loadUsers() {
      this.tableData.loading = true
      return api.get(`/manage/users`).then((response) => {
        if (response.status === 200) {
          this.tableData.items = response.data
        }
      }).finally(() => this.tableData.loading = false)
    },
    async loadPermissions() {
      return api.get(`/manage/permissions`).then((response) => {
        if (response.status === 200) {
          this.catalogData.permissions = response.data
        }
      })
    },
    clickEditUser(user) {
      Vue.set(this.edit, 'user', user)
      this.$bvModal.show('modal-edit-user')
    },
    clickDeleteUser(user) {
      this.$bvModal.msgBoxConfirm(
          `Вы действительно хотите удалить пользователя "${ user.fullname }"?`,
          {
            okVariant: 'danger',
            okTitle: 'Да',
            cancelTitle: 'Нет',
            hideHeaderClose: false,
            centered: true
          }
      ).then(confirmed => {
        if (!confirmed) return

        api.delete(`/manage/users/${ user.id }`)
            .then(() => {
              this.$bvToast.toast('Пользователь успешно удален', {
                ...this.commonToaster, variant: 'success'
              })
              this.loadUsers()
            })
            .catch((error) => {
              console.log(error)
            })

      })
    }
  }
}
</script>

<template>
  <b-card title="Управление пользователями">
    <modal-edit-user :edit="edit" :permissions="catalogData.permissions" @reload="loadUsers"/>

    <b-card-text>
      <b-button-toolbar class="button_toolbar">
        <b-button-group class="mx-1">
          <b-btn v-b-modal.modal-add title="Добавить" variant="outline-primary"
                 @click="clickEditUser()">
            <font-awesome-icon icon="square-plus"/>
            Добавить
          </b-btn>
          <b-btn
              :disabled="tableData.loading"
              variant="outline-primary"
              @click="loadUsers"
          >
            <font-awesome-icon v-if="!tableData.loading" fixed-width icon="rotate-right"/>
            <b-spinner v-else small type="border"></b-spinner>
            Обновить
          </b-btn>
        </b-button-group>
      </b-button-toolbar>

      <div class="float-right">
        <b-form-select v-model="tableData.options.perPage"
                       :options="tableData.options.pageOptions"
                       :select-size="1"
                       class="page-select">
        </b-form-select>
        <div class="page-per float-right">По:</div>
      </div>

      <b-pagination v-model="tableData.options.currentPage"
                    :per-page="tableData.options.perPage"
                    :total-rows="tableData.items.length"
                    class="float-right mx-0 my-0"></b-pagination>
      <div class="page-total float-right">Всего: {{ tableData.items.length }}</div>
      <b-input-group class="float-right w-25 mr-2">
        <b-form-input v-model="filter" placeholder="Поиск по таблице"></b-form-input>
        <b-input-group-append>
          <b-btn :disabled="!filter" class="page-filter-clear" @click="filter = undefined">
            <font-awesome-icon icon="eraser"/>
          </b-btn>
        </b-input-group-append>
      </b-input-group>

      <b-table
          :busy.sync="tableData.loading"
          :current-page="tableData.options.currentPage"
          :fields="tableData.headers"
          :filter="filter"
          :items="tableData.items"
          :per-page="tableData.options.perPage"
          :sort-by.sync="tableData.options.sortBy"
          :sort-desc.sync="tableData.options.sortDesc"
          hover
          responsive
          show-empty
          small
          striped
      >
        <template #table-busy>
          <div class="text-center my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Данные загружаются...</strong>
          </div>
        </template>
        <template #cell(permissions)="data">
          <b-badge v-for="permission in data.value" :key="permission.name"
                   :class="permission.name.startsWith('generators') ? 'generators' : permission.name.startsWith('corpus') ? 'corpus' : 'common'"
                   class="mx-1">
            {{ permission.value }}
          </b-badge>
        </template>

        <template #cell(actions)="data">
          <b-button-group>
            <b-btn v-b-modal.modal-add variant="outline-secondary" @click="clickEditUser(data.item)">
              <font-awesome-icon fixed-width icon="pen" title="Редактировать"/>
            </b-btn>
            <b-btn v-b-modal.modal-delete variant="outline-secondary"
                   @click="clickDeleteUser(data.item)">
              <font-awesome-icon fixed-width icon="trash" title="Удалить"/>
            </b-btn>
            <set-password :icon="true" :user-id="data.item.id" :user-name="data.item.fullname"
                          button-variant="outline-secondary"/>
          </b-button-group>
        </template>
      </b-table>
    </b-card-text>
  </b-card>
</template>

<style scoped>
.badge.common {
  background-color: #b4b8b3;
  color: black;

}

.badge.generators {
  background-color: #e3b247;
  color: black;
}

.badge.corpus {
  background-color: #92b1cd;
  color: black;
}
</style>