<script>
import GeneratorMixin from '@/mixins/GeneratorMixin'
import SelectedChunks from './SelectedChunks.vue'
import BtnGenerator from '@/components/BtnGenerator.vue'

export default {
  data() {
    return {
      processedNum: 0,
      parents: []
    }
  },
  mixins: [GeneratorMixin],
  props: {
    selectedChunks: Object,
    sections: Array,
    processingChunks: Array,
    selectedChunksNum: Number
  },
  components: { BtnGenerator, SelectedChunks },
  emits: ['unselect-excluded', 'on-ok'],
  methods: {
    onOk() {
      this.$bvToast.toast(`Запущена фоновая генерация заголовков`, {
        ...this.commonToaster, variant: 'success'
      })
      this.$emit('on-ok')
      this.$bvModal.hide('modal-generate-headings')
    }
  },
  computed: {
    bgItems() {
      const res = {}
      for (const sectionIndex in this.selectedChunks) {
        for (const chunk of this.selectedChunks[sectionIndex]) {
          res[chunk.id] = chunk
        }
      }
      return res
    },
    busy() {
      return this.processedNum < this.selectedChunksNum
    }
  }
}
</script>

<template>
  <b-modal
      id="modal-generate-headings"
      :busy="busy"
      centered
      lazy
      ok-title="Генерировать"
      scrollable
      size="lg"
      static
      title="Генерация заголовков"
  >
    <selected-chunks :processing-chunks="processingChunks" :sections="sections" :selected-chunks="selectedChunks"/>

    <template #modal-footer>
      <b-btn variant="outline-secondary" @click="$emit('unselect-excluded')">
        Исключить исключенные
      </b-btn>
      <btn-generator
          :bg-items="bgItems"
          :callback="onOk"
          background
          btn-variant="outline-primary"
          list-processing
          prompt-name="heading"
      />
    </template>
  </b-modal>
</template>

<style>
#modal-generate-headings .modal-content {
  min-height: 300px;
}
</style>