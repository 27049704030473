<!--suppress JSDeprecatedSymbols -->
<script>
import DocumentMixin from '@/mixins/DocumentMixin'
import UrlMixin from '@/mixins/UrlMixin'
import GeneratorCardMixin from '@/mixins/GeneratorCardMixin'

import DistractorSearch from '@/components/DistractorSearch.vue'
import QuizSearch from '@/components/QuizSearch.vue'

import ModalEditQuest from '../modals/EditQuest.vue'
import Vue from 'vue'
import { mapGetters } from 'vuex'
import BtnGenerator from '@/components/BtnGenerator.vue'

export default {
  name: 'GeneratorQuiz',
  components: {
    BtnGenerator,
    DistractorSearch,
    QuizSearch,
    ModalEditQuest
  },
  mixins: [GeneratorCardMixin, DocumentMixin],
  data() {
    return {
      params: {
        text: '',
        speciality: '',
        discipline: '',
        distractorTheme: '',
        examples: ''
      },
      fromParagraphData: undefined
    }
  },
  mounted() {
    // _FIXME убрать предзаполнение
    // this.documentLink = 'https://library.mededtech.ru/rest/documents/KP654/index.html#paragraph_9njhu1'
    // this.params.speciality = 'Пульмонология'
    // this.params.discipline = 'Внебольничная пневмония'
    // this.params.distractorTheme = 'Значение ранней диагностики вирусов при тяжелой внебольничной пневмонии'

    if (this.$route.query.qa) {
      this.fromParagraphData = UrlMixin.decode(this.$route.query.qa)
      this.fromUrlParams(this.fromParagraphData)
      this.fromParagraphData.questionAnswerText = this.fromParagraphData.questions.map(x => `Вопрос: ${ x.question }\nОтвет: ${ x.answer }`).join('\n\n')
      this.clickLoadDataFromLink()
    }
  },
  computed: {
    ...mapGetters(['HAS_PERMISSION'])
  },
  methods: {
    onGenerate(data) {
      this.result = data
    },

    setDistractorTheme(value) {
      this.params.distractorTheme = value
    },

    calcTitle() {
      const t = ['Генератор тестов на основе']
      if (this.fromParagraphData) {
        t.push('пар вопрос-ответ')
      }
      else {
        t.push('текста')
      }
      if (this.fromChunkData) {
        t.push(`для чанка "${ this.fromChunkData.chunkTitle }"`)
      }
      return t.join(' ')
    },

    createQuestInChunk(quest) {
      Vue.set(this.edit, 'editQuest', quest)
      Vue.set(this.edit, 'chunk', { id: this.fromChunkData.chunkId, title: this.fromChunkData.chunkTitle })
      Vue.set(this.edit, 'questions', [])
      Vue.set(this.edit, 'questionType', 'quiz')
      this.$bvModal.show('modal-edit-quest')
    }
  },
}
</script>

<template>
  <b-container fluid>
    <modal-edit-quest :edit="edit"/>
    <b-row class="mt-3">
      <b-col cols="12" md="6">
        <b-card :title="calcTitle()">
          <b-card-text>
            <b-card class="mb-1" no-body>
              <b-card-header class="p-1" header-tag="header" role="tab">
                <b-button v-if="documentParams.name" v-b-toggle.accordion-1 block variant="secondary">
                  {{ documentParams.name }}
                </b-button>
                <template v-else>Ссылка на документ</template>
              </b-card-header>
              <b-collapse id="accordion-1" :visible="linkShow" accordion="my-accordion" role="tabpanel">
                <b-card-body>
                  <b-input v-model="documentLink" :state="documentLink > ''"
                           lazy
                           placeholder="Вставьте ссылку на параграф в библиотеке в виде https://library.mededtech.ru/rest/documents/134_2020/#paragraph_ps0nr2"
                           trim/>

                  <b-btn :disabled="running" icon variant="primary" @click="clickLoadDataFromLink">
                    <font-awesome-icon icon="share-from-square"/>
                    Загрузить данные из документа по ссылке
                  </b-btn>
                </b-card-body>
              </b-collapse>
            </b-card>

            <template v-if="documentParams.name">
              <!--<b-tabs  content-class="mt-3">-->
              <!--<b-tab active title="Параметры">-->
              <b-input-group class="mb-3">
                <b-input-group-prepend is-text>
                  Специальность
                </b-input-group-prepend>

                <b-form-select v-model="params.speciality" :options="documentParams.specList"
                               :state="params.speciality > '' ? null : false"
                               text-field="name" value-field="name"/>
              </b-input-group>
              <b-input-group class="mb-3">
                <b-input-group-prepend is-text>
                  Дисциплина
                </b-input-group-prepend>

                <b-form-input v-model="params.discipline" :state="params.discipline > '' ? null : false" trim/>
              </b-input-group>

              <b-input-group class="mb-3">
                <b-input-group-prepend is-text>
                  Тема дистракторов
                </b-input-group-prepend>

                <b-form-input v-model="params.distractorTheme" :state="params.distractorTheme > '' ? null : false"
                              trim/>
              </b-input-group>

              <!--<b-input-group class="mb-3">
                <b-input-group-prepend is-text>
                  Температура
                </b-input-group-prepend>

                <b-form-input
                    v-model="generationParams.temperature"
                    max="2" min="0" step="0.05" type="range"
                />

                <b-input-group-append id="tempRangeValue" is-text>
                  {{ generationParams.temperature }}
                </b-input-group-append>

              </b-input-group>-->


              <b-form-group>
                <b-button-group>
                  <!--<table2-text :text="params.text" class="mr-1 ml-1" @input="setText"/>-->
                  <!--<header2-distractor :text="params.text" class="mr-1" @input="setDistractorTheme"/>-->
                  <!--<rephrase :text="params.text" class="mr-1" @input="setText"/>-->

                  <btn-generator
                      :callback="setDistractorTheme"
                      :params="{text: params.text}"
                      btn-text="Заголовок"
                      prompt-name="heading"
                  />
                  <btn-generator
                      :callback="setText"
                      :params="{text: params.text}"
                      btn-text="Таблицу в текст"
                      prompt-name="table2text"
                  />
                  <btn-generator
                      :callback="setText"
                      :params="{text: params.text}"
                      btn-text="Парафраз"
                      prompt-name="rephrase"
                  />
                  <b-btn variant="outline-dark" @click="clickRevealAbbrev">
                    Аббревиатуры
                  </b-btn>
                </b-button-group>
              </b-form-group>

              <b-input-group v-if="fromParagraphData === undefined" class="mb-3">
                <b-input-group-prepend is-text>
                  Текст
                </b-input-group-prepend>

                <b-form-textarea v-model="params.text" :state="params.text > '' ? null : false" rows="8"
                                 trim/>

              </b-input-group>

              <b-input-group v-if="fromParagraphData !== undefined" class="mb-3">
                <b-input-group-prepend is-text>
                  Пары вопрос-ответ
                </b-input-group-prepend>

                <b-form-textarea v-model="fromParagraphData.questionAnswerText"
                                 :state="fromParagraphData.questionAnswerText > '' ? null : false"
                                 rows="5"/>

              </b-input-group>
              <!--<b-input-group class="mb-3">
                <b-input-group-prepend is-text>
                  Примеры
                </b-input-group-prepend>

                <b-form-textarea v-model="params.examples" rows="5"/>
              </b-input-group>-->
              <!--</b-tab>-->

              <!--<prompt-tab :generation-params="generationParams" :messages="messages"/>-->
              <!--</b-tabs>-->
            </template>
          </b-card-text>
          <b-card-footer>
            <!--            <b-alert variant="warning" :show="!linkShow && !(documentParams && params.speciality && params.discipline)">
                          Заполните обязательные поля для возможности генерации
                        </b-alert>-->
            <template v-if="documentParams.name && params.speciality && params.discipline">
              <btn-generator
                  :callback="onGenerate"
                  :params="{...params, chunkText: params.text, documentName: documentParams.name}"
                  :prompt-name="!fromParagraphData ? 'quiz' : 'quizBasedQA'"
                  btn-variant="outline-primary"
              />
            </template>
          </b-card-footer>
        </b-card>
      </b-col>

      <b-col cols="12" md="6">
        <b-tabs content-class="mt-3">
          <b-tab active title="Результат">
            <b-card>
              <b-card-text>
                <h4>
                  Результат
                  <!--<stopwatch ref="stopwatch" style="float: right;"/>-->
                </h4>
                <template v-if="result.tests || result">
                  <b-card v-for="(quest, questIndex) in (result.tests || result)"
                          :key="questIndex"
                          class="question mb-2">
                    <template #header>
                      <span>
                        {{ questIndex + 1 }}. {{ quest.question }}
                      </span>
                      <b-button-group
                          v-if="fromChunkData && fromChunkData.chunkId  && HAS_PERMISSION('corpus_quest_edit')"
                          size="sm">
                        <b-btn title="Скопировать вопрос в чанк"
                               variant="outline-secondary" @click="createQuestInChunk(quest)">
                          <font-awesome-icon
                              fixed-width
                              icon="clone"
                          />
                        </b-btn>
                      </b-button-group>
                    </template>
                    <div class="ml-2">

                      <p>А. {{ quest.answer }}</p>
                      <p v-for="(distractor, disIndex) in quest.distractors">
                        {{ ['Б', 'В', 'Г'][disIndex] }}. {{ distractor }}
                      </p>

                    </div>
                  </b-card>

                </template>

              </b-card-text>
            </b-card>
          </b-tab>
          <b-tab title="Поиск дистракторов">
            <b-card>
              <b-card-text>
                <distractor-search :theme="params.distractorTheme"/>
              </b-card-text>
            </b-card>
          </b-tab>
          <b-tab title="Поиск тестов">
            <b-card>
              <b-card-text>
                <quiz-search :theme="params.distractorTheme"/>
              </b-card-text>
            </b-card>
          </b-tab>
        </b-tabs>

      </b-col>
    </b-row>
  </b-container>
</template>

<style scoped>

#tempRangeValue div {
  width: 55px;
}

.card-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
</style>