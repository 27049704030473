import { AiAlias, Prompt } from './main'
import json_schema from './quizSchema.json'

const systemContent = `Тебе нужно принять роль врача по специальности {speciality} и одновременно тестолога - методиста.`
const userContent = `Нужно написать тесты на основе текста, который я приведу ниже. Требования к тестам следующие:
1) Тест должен иметь четыре ответа, только один верный.
2) Условие теста должно представлять собой одну часть предложения, правильный ответ или дистрактор – вторую часть предложения. Между условием теста и ответами должно быть согласование по смыслу, в роде, числе и падеже.
3) Если в тексте упоминается определенная болезнь или пациенты с определенной болезнью, то эта категория пациентов и эта болезнь должны учитываться в условиях всех тестов, которые будут генерироваться из этого текста, но не в верных ответах этих тестов.
4) Условие теста не должно быть вопросом.
5) В условии теста строго не должно использоваться отрицание - не должно быть частицы не.
6) Ответы теста строго не должны быть длиннее условия теста. Повторяющиеся части ответов теста нужно переносить в условие теста.
7) Не должно быть слов Верно или Неверно в ответах теста.
8) Длина дистракторов не должна сильно отличаться от длины верного ответа. Все ответы одного теста должны быть приблизительно одной длины и степени подробности. Если длина верного ответа будет существенно отличаться от длины дистракторов, то получишь штраф.
9) Каждый из дистракторов должен быть в рамках подраздела медицинской специальности, указанного ниже, в спецификации тестов. Подраздел медицинской специальности является также заголовком того текста, который будет тебе выдан как основа для генерации тестов. Поэтому при выборе дистракторов тебе нужно их искать в рамках этого подраздела медицинской специальности, как темы дистракторов.
Например, рассмотрим текст «При прогрессировании атеросклеротического процесса, вызванного дислипидемией (ДЛП), атеросклероз артерий нижних конечностей сопровождается клинической картиной перемежающей хромоты. При отсутствии соответствующего лечения заболевание прогрессирует и может закончиться развитием гангрены нижних конечностей.». Для этого текста был задан заголовок (подраздел медицинской специальности) – «Перемежающая хромота как проявление атеросклероза нижних конечностей при ДЛП». Для этого текста и этого заголовка был сгенерирован такой тест (А – верный ответ, Б, В, Г - дистракторы): 
При отсутствии соответствующего лечения атеросклероз артерий нижних конечностей может закончиться развитием
А. гангрены нижних конечностей
Б. тромбоза коронарных артерий
В. инфаркта миокарда
Г. ишемического инсульта
Здесь дистракторы В, Г являются неправильными, т.к. они выходят за рамки заголовка текста (подраздела медицинской специальности), хотя и находятся в рамках медицинской специальности. Правильными дистракторами в данном случае будут:
В. варикозного расширения вен 
Г. полиартроза нижних конечностей 
Поэтому при выборе дистракторов обращай особое внимание на подраздел медицинской специальности. Если дистракторы будут за рамками подраздела медицинской специальности, то получишь штраф.
10) Необходимо учитывать, что дистрактор - это неверный, но правдоподобный ответ. Поэтому дистракторы должны быть максимально правдоподобны. Все дистракторы к каждому тесту должны быть одинаково привлекательными для испытуемых, не знающих правильного ответа. Неправдоподобные дистракторы не предлагать.
11) Очень важно, чтобы дистрактор не являлся правильным ответом или частично правильным ответом (частью правильного ответа). Например, «нефротический синдром», «гломерулонефрит» являются частью верного ответа «хроническая болезнь почек», или, например, если верный ответ = развитие сердечно-сосудистых осложнений, то нельзя предлагать дистрактор = инсульт, поскольку инсульт является сердечно-сосудистым осложнением. Если дистрактор или его часть будет правильным ответом - будешь получать большой штраф.
12) Верный ответ и дистракторы должны быть понятиями одного уровня группировки. Например, если верный ответ - конкретный лекарственный препарат (ЛП), то и дистракторы должны быть ЛП. Например, если верный ответ = эзетимиб (это ЛП), то дистракторы = антидепрессанты, анальгетики, антигистаминные средства - не подходят, поскольку это группы ЛП.  Аналогично, если верный ответ - группа ЛП, то и дистракторы должны быть группой ЛП.
13) Необходимо учитывать при создании тестов их общую направленность, которая определяется: медицинской специальностью, разделом этой специальности и подразделом этой специальности.   Предложенные тобой тесты должны быть в рамках этой специальности, раздела этой специальности, подраздела этой специальности и строго на основе предложенного мной текста. При этом дистракторы в тестах ты можешь брать не только из предложенного текста.
14) Если предложенный тебе текст представляет собой список (список может быть нумерованным или просто через точку с запятой, или даже через запятую), то тесты из этого списка нужно делать так: 
каждый элемент списка должен стать правильным ответом в отдельном тесте, к которому нужно будет найти дистракторы; в условии теста нужно писать примерно такую фразу (каждый раз исходя из контекста): одним из факторов, влияющих на (какой то признак из контекста) является .... Вот пример обработки такого списка:
текст на входе: "Стойкое и длительное повышение артериального давления (АД) обусловлено изменением соотношения трех гемодинамических показателей: 1) повышением общего периферического сосудистого сопротивления (ОПСС); 2) увеличением сердечного выброса (минутного объема); 3) увеличением объема циркулирующей крови (ОЦК)."
Пример теста из этого текста (ответы здесь отмечены буквами А, Б, В, Г, правильный ответ – А, остальные дистракторы):
"Одним из факторов, влияющих на стойкое и длительное повышение артериального давления, является
А. увеличение сердечного выброса
Б. уменьшение сердечного выброса
В. снижение объема циркулирующей крови
Г. уменьшение общего периферического сосудистого сопротивления".
При этом нельзя использовать элементы списка как дистракторы к другому элементу списка, поскольку дистрактор не должен быть верным (частично верным) ответом.
15) Тест не должен иметь очевидный ответ. Например, вот из такого текста:
"При первичном гиперальдостеронизме и симптоматической гипертонии жалобы обусловлены основным заболеванием и включают мышечную слабость, полиурию, полидипсию, запоры", был сгенерирован такой тест с очевидным ответом (верный ответ - А.):
При первичном гиперальдостеронизме и симптоматической гипертонии жалобы обусловлены
А. основным заболеванием
Б. побочными эффектами лекарств
В. психологическими факторами
Г. внешними условиями
Правильный тест из этого текста, например, такой:
При первичном гиперальдостеронизме и симптоматической гипертонии жалобы обусловлены основным заболеванием и включают
А. мышечную слабость
Б. боль в грудной клетке
В. головокружение
Г. одышку.

Далее я передаю тебе спецификацию для тестов и текст для генерации:
Вот название медицинской специальности: {speciality}.
Вот название раздела медицинской специальности: {discipline}.
Вот название подраздела медицинской специальности: {distractorTheme}.
Вот название документа: {documentName}.

Вот текст из этого документа, на основе которого нужно сделать тесты:

{chunkText}

Вот пример такого теста в json формате: {
  "tests": [
    {
      "question": "Препаратами первой линии для снижения частоты и интенсивности приступов стенокардии являются",
      "answer": "бета-адреноблокаторы и антагонисты кальция",
      "distractors": [
        "антагонисты кальция и пролонгированные нитраты",
        "ивабрадин и антагонисты кальция",
        "антагонисты кальция и дигоксин"
      ],
    }
  ]
}
`
export default new Prompt({
  messages: {
    system: systemContent,
    user: userContent
  },
  model: AiAlias.STD,
  params: {
    response_format: {
      type: 'json_schema',
      json_schema
    }
  }
})
