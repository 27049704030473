import { AiAlias, Prompt } from './main'

const systemContent = ``
const userContent = `Я дам тебе абзац из книги и тебе нужно придумать короткую фразу для заголовка этого абзаца.
Не включай слово "Заголовок" или кавычки в ответ.
Вот этот текст: "{text}".
Подумай и приступай.`
export default new Prompt({
  messages: {
    system: systemContent,
    user: userContent,
  },
  model: AiAlias.MINI,
})
