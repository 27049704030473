import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '@/pages/Login.vue'
import Mesh from '@/pages/Mesh/Mesh.vue'
import MeshTree from '@/pages/Mesh/MeshTree.vue'
import MeshDescriptor from '@/pages/Mesh/MeshDescriptor.vue'
import MeshSearch from '@/pages/Mesh/MeshSearch.vue'
import Documents from '@/pages/Documents/Documents.vue'
import Methodical from '@/pages/Methodical/Methodical.vue'
import MKBRoot from '@/pages/MKB/MKBroot.vue'
import MKBTree from '@/pages/MKB/MKBTree.vue'
import MKBSearch from '@/pages/MKB/MKBSearch.vue'
import Profile from '@/pages/Profile/Profile.vue'
import ManageUsers from '@/pages/Admin/ManageUsers.vue'

import GeneratorQuiz from '@/pages/Corpus/Generators/Quiz.vue'
import GeneratorQA from '@/pages/Corpus/Generators/QuestAns.vue'

import CorpusRoutes from './corpus'
import AiHistory from '@/pages/Admin/AiHistory.vue'

Vue.use(VueRouter)

const routes = [
  ...CorpusRoutes,
  {
    name: Login.name,
    path: '/login',
    component: Login
  },
  {
    name: Documents.name,
    path: '/docs',
    component: Documents
  },
  {
    name: Profile.name,
    path: '/profile',
    component: Profile,
    meta: { requiresAuth: true }
  },
  {
    name: ManageUsers.name,
    path: '/manage/users',
    component: ManageUsers,
    meta: { requiresAuth: true }
  },
  {
    name: AiHistory.name,
    path: '/manage/ai-history',
    component: AiHistory,
    meta: { requiresAuth: true }
  },
  {
    name: Methodical.name,
    path: '/methodical',
    component: Methodical
  },
  {
    name: MKBRoot.name,
    path: '/mkb',
    component: MKBRoot,
    redirect: '/mkb/search',
    children: [
      {
        name: MKBTree.name,
        path: 'tree',
        component: MKBTree
      },
      {
        name: MKBSearch.name,
        path: 'search',
        component: MKBSearch
      }
    ]
  },
  {
    name: Mesh.name,
    path: '/mesh',
    component: Mesh,
    redirect: '/mesh/search',
    children: [
      {
        name: MeshTree.name,
        path: 'tree',
        component: MeshTree
      },
      {
        name: MeshSearch.name,
        path: 'search',
        component: MeshSearch
      },
      {
        name: MeshDescriptor.name,
        path: ':descriptorId',
        component: MeshDescriptor
      }
    ]
  },

  {
    name: GeneratorQuiz.name,
    path: '/quest-quiz/generator',
    component: GeneratorQuiz,
    meta: { requiresAuth: true }
  },
  {
    name: GeneratorQA.name,
    path: '/quest-qa/generator',
    component: GeneratorQA,
    meta: { requiresAuth: true }
  },

  {
    path: '*',
    redirect: '/docs'
  }

]

export default new VueRouter({
  mode: 'history',
  routes
})