<script>
import VJsoneditor from 'v-jsoneditor'
import Prompt from '../ai'
import { AiAlias } from '@/ai/main'
import api from '@/api'
import { mapGetters } from 'vuex'


export default {
  name: "BtnGenerator",
  props: {
    params: {
      type: Object,
      default: null
    },
    callback: {
      type: Function,
      default: undefined
    },
    promptName: {
      type: String,
      required: true
    },
    btnVariant: {
      type: String,
      default: 'outline-dark'
    },
    btnText: {
      type: String,
      default: 'Генерировать'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    listParams: {
      type: Array[Object],
      default: undefined
    },
    background: Boolean,
    bgItems: Object
  },
  components: { VJsoneditor },
  data() {
    return {
      models: [],
      prompt: Prompt[this.promptName],
      processing: false
    }
  },
  methods: {
    resetPrompt() {
      this.prompt.mapVars(this.params)
    },
    callGenerator() {
      this.processing = true

      api.post(`/generator/from-prompt`, {
        promptAlias: this.promptName,
        messages: [
          {
            role: 'system',
            content: this.prompt.messages.system
          },
          {
            role: 'user',
            content: this.prompt.messages.user
          }
        ],
        params: { model: this.prompt.model, ...this.prompt.params }
      })
          .then(({ data }) => {
            if (this.callback) {
              this.callback(data)
            }
          })
          .catch((error) => {
            switch (error.response.status) {
              case 401:
                this.$bvToast.toast(`У вас недостаточно разрешений для вызова генерации тестов`, {
                  ...this.commonToaster, variant: 'danger'
                })
                break
              case 422:
                if (error.response.data === 'doc_not_specified') {
                  this.$bvToast.toast(`Укажите идентификатор или название документа`, {
                    ...this.commonToaster, variant: 'danger'
                  })
                  break
                }
                this.$bvToast.toast(`Передано что-то невалидное`, {
                  ...this.commonToaster, variant: 'danger'
                })
                break
              case 451:
                this.$bvToast.toast(`Сервис генерации недоступен`, {
                  ...this.commonToaster, variant: 'danger'
                })
                break
              default:
                console.log(error)
                this.$bvToast.toast('Что-то пошло не так', {
                  ...this.commonToaster, variant: 'danger'
                })
                break
            }
          })
          .finally(() => {
            this.processing = false
          })
    },
    callBgGenerator() {
      this.processing = true

      const items = {}

      for (const key in this.bgItems) {
        const messages = this.prompt.previewVars(this.bgItems[key])
        items[key] = [
          {
            role: 'system',
            content: messages.system
          },
          {
            role: 'user',
            content: messages.user
          }
        ]
      }

      api.post(`/generator/from-prompt-bg`, {
        promptAlias: this.promptName,
        items,
        params: { model: this.prompt.model, ...this.prompt.params }
      })
          .then(({ data }) => {
            if (this.callback) {
              this.callback(data)
            }
          })
          .catch((error) => {
            switch (error.response.status) {
              case 401:
                this.$bvToast.toast(`У вас недостаточно разрешений для вызова генерации тестов`, {
                  ...this.commonToaster, variant: 'danger'
                })
                break
              case 422:
                if (error.response.data === 'doc_not_specified') {
                  this.$bvToast.toast(`Укажите идентификатор или название документа`, {
                    ...this.commonToaster, variant: 'danger'
                  })
                  break
                }
                this.$bvToast.toast(`Передано что-то невалидное`, {
                  ...this.commonToaster, variant: 'danger'
                })
                break
              case 451:
                this.$bvToast.toast(`Сервис генерации недоступен`, {
                  ...this.commonToaster, variant: 'danger'
                })
                break
              default:
                console.log(error)
                this.$bvToast.toast('Что-то пошло не так', {
                  ...this.commonToaster, variant: 'danger'
                })
                break
            }
          })
          .finally(() => {
            this.processing = false
          })
    }
  },
  computed: {
    ...mapGetters(['HAS_PERMISSION']),
    notMapped() {
      const regex = /{\w+}/gm

      let m
      const notMapped = []

      while ((m = regex.exec([this.prompt.messages.system, this.prompt.messages.user].join(' '))) !== null) {
        // This is necessary to avoid infinite loops with zero-width matches
        if (m.index === regex.lastIndex) {
          regex.lastIndex++
        }

        m.forEach((match) => {
          if (!notMapped.includes(match)) {
            notMapped.push(match)
          }
        })
      }
      return notMapped
    },
    modelColor() {
      return {
        [AiAlias.MINI]: 'success',
        [AiAlias.STD]: 'warning',
        [AiAlias.PRO]: 'danger',
        [AiAlias.ALT]: 'purple'
      }
    },
    elementId() {
      return `${ this.promptName }-${ new Date().getTime() }`
    }
  },
  watch: {
    params: {
      handler() {
        this.prompt.mapVars(this.params)
      },
      deep: true
    }
  },
  mounted() {
    api.get('/generator/models').then(response => {
      this.models = response.data
    })
    this.prompt.mapVars(this.params)
  }
}
</script>

<template>
  <b-btn
      :id="elementId"
      :disabled="disabled || processing"
      :variant="btnVariant"
      @click="!background ? callGenerator() : callBgGenerator()"
  >
    {{ btnText }}
    <template v-if="!processing">
      <b-avatar :variant="modelColor[this.prompt.model]" icon="fa-microchip" size="0.5rem"/>
      <template v-if="HAS_PERMISSION('generators_prompt')">
        <b-popover :target="elementId" placement="top" triggers="hover focus">
          <!--<template #title>Параметры генерации</template>-->
          <b-form-group label="Модель для генерации">
            <b-select v-model="prompt.model">
              <option v-for="model in models" :value="model.id">
                {{ model.name }} ({{ model.code }})
              </option>
            </b-select>
          </b-form-group>

          <b-form-group :label="`Температура ${prompt.params.temperature}`">
            <b-form-input
                v-model="prompt.params.temperature"
                max="1"
                min="0"
                number
                step="0.1"
                type="range"
            />
          </b-form-group>

          <b-btn @click="$refs['prompt-modal'].show()">
            Показать промпт
          </b-btn>
        </b-popover>
      </template>
    </template>
    <template v-else>
      <b-spinner label="processing..." small variant="primary"/>
    </template>

    <b-modal ref="prompt-modal"
             cancel-title="Вернуть исходное"
             hide-header
             no-close-on-backdrop
             ok-title="Сохранить"
             size="xl"
             title="Промпт"
             @ok="$refs['prompt-modal'].hide()"
             @cancel.prevent="resetPrompt()"
    >
      <b-form-group label="Системный">
        <b-form-textarea v-model.trim="prompt.messages.system" rows="2"/>
      </b-form-group>

      <b-form-group label="Пользовательский">
        <b-form-textarea v-model.trim="prompt.messages.user" rows="10"/>
      </b-form-group>

      <template v-if="prompt?.params?.response_format?.json_schema">
        <b-button v-b-toggle.response-format variant="outline-dark">
          Формат ответа
        </b-button>
        <b-collapse id="response-format">
          <v-jsoneditor
              v-model="prompt.params.response_format.json_schema"
              :options="{
              mainMenuBar: true,
              statusBar: true,
              mode: 'code',
              language: 'ru',
              enableSort: false
            }"
              height="40vh"
          />
        </b-collapse>
      </template>

      <b-alert v-if="background" show variant="warning">
        <div>Переменные {{ notMapped.join(', ') }} будут взяты из каждого элемента списка.</div>
        <div>Вы должны оставить формат "{var}" при редактировании, если хотите чтобы меппинг состоялся</div>
      </b-alert>
    </b-modal>

  </b-btn>
</template>

<style scoped>
.badge-purple {
  background-color: var(--purple);
}
</style>