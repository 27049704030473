<script>

import ChunkText from '@/pages/Corpus/ChunkText.vue'

export default {
  components: { ChunkText },
  data() {
    return {
      processedNum: 0,
      parents: []
    }
  },
  props: {
    selectedChunks: Object,
    processingChunks: Array,
    sections: Array,
    showText: Boolean
  },
  emits: ['unselect-excluded'],
  methods: {

    async onOk() {

    }
  },
  computed: {}
}
</script>

<template>
  <div>
    <template v-for="(chunks, sectionIndex) in selectedChunks">
      <template v-if="chunks.length > 0">
        <b>Раздел: {{ sections[sectionIndex].title }} [{{ chunks.length }}]</b>
        <div
            v-for="chunk in chunks"
            :class="{
                'excluded': chunk.is_excluded
              }"
            class="flex-column align-items-start ml-3"
        >
          <div class="d-flex w-100 justify-content-between title">
            <span>
              <font-awesome-icon
                  v-if="processingChunks && processingChunks.includes(chunk.id)"
                  fixed-width
                  icon="spinner"
                  spin-pulse
              />
              {{ chunk.title || 'Заголовок отсутствует' }}</span>
            <span v-if="chunk.is_excluded">[Искл.]</span>
          </div>

          <small v-if="showText">
            <chunk-text :chunk="chunk"/>
          </small>

        </div>
      </template>
    </template>
  </div>
</template>

<style scoped>

</style>