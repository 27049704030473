<script>
import DocumentMixin from '@/mixins/DocumentMixin'
import CardMixin from '@/mixins/CardMixin'
import { klona } from 'klona'
import api from '@/api'
import { mapGetters } from 'vuex'
import Vue from 'vue'
import UrlMixin from '@/mixins/UrlMixin'
import CorpusContentMixin from '@/mixins/CorpusContentMixin'
import LBtn from '@/components/LBtn.vue'
import QuizSearch from '@/components/QuizSearch.vue'

export default {
  mixins: [DocumentMixin, CardMixin, CorpusContentMixin],
  components: { QuizSearch, LBtn },
  props: {
    chunk: Object
  },
  data() {
    return {
      questionTypes: { quiz: 'Тесты', question_answer: 'Вопросы-ответы' },
      items: {},
      editQuest: {
        type: '',
        data: {
          id: ''
        }
      }
    }
  },
  methods: {
    onShow() {
      this.loadQuestions('quiz').then(() => {
        this.loadQuestions('question_answer')
      })
    },
    async loadQuestions(questType) {
      return api.get(`/corpus/chunk/${ this.chunk.id }/question/${ questType }`)
          .then(({ data }) => {
            Vue.set(this.items, questType, data)
          })

    },
    clickEditQuest(quest, questType) {
      this.editQuest.type = questType
      if (!quest.id) {
        quest = { question: '', answer: '' }
        if (questType === 'quiz') {
          quest.distractors = Array(3).fill('')
        }
        this.items[questType].push(quest)
      }
      this.editQuest.data = klona(quest)
    },
    async saveQuest(quest) {
      const questType = this.editQuest.type
      if (this.editQuest.data.id)
        return api.post(`/corpus/question/${ questType }/${ this.editQuest.data.id }`, {
          ...this.editQuest.data
        })
            .then(({ data }) => {
              Object.keys(quest).forEach(key => {
                quest[key] = data[key]
              })
              this.clearQuestEdit()
              this.$bvToast.toast(
                  'Вопрос сохранен', { ...this.commonToaster, variant: 'success' }
              )
            })
            .catch(({ error }) => {
              console.error(error)
            })

      else {
        return api.post(`/corpus/chunk/${ this.chunk.id }/${ questType }`, {
          ...this.editQuest.data
        })
            .then(({ data }) => {
              this.clearQuestEdit()
              this.items[questType].push(data)
              this.$parent.chunksStats[this.chunk.id][`${ questType }_num`]++
              this.$bvToast.toast(
                  'Вопрос создан', { ...this.commonToaster, variant: 'success' }
              )
            })
            .catch(({ error }) => {
              console.error(error)
            })
      }
    },
    async questDone(questType, quest, is_done) {
      return api.post(`/corpus/question/${ questType }/${ quest.id }`, { is_done })
          .then(({ data }) => {
            Object.keys(data).forEach(key => {
              quest[key] = data[key]
            })
            if (quest.is_done) {
              this.$parent.chunksStats[this.chunk.id][`${ questType }_done_num`]++
            }
            else {
              delete quest.when_is_done
              this.$parent.chunksStats[this.chunk.id][`${ questType }_done_num`]--
            }
            this.$bvToast.toast(
                data.is_done ? 'Вопрос отмечен завершенным' : 'Отметка завершенности снята',
                { ...this.commonToaster, variant: 'success' }
            )
          })
          .catch(({ error }) => {
            console.error(error)
          })
    },
    clearQuestEdit() {
      if (!this.editQuest.data.id) {
        this.items[this.editQuest.type].pop()
      }
      this.editQuest.type = ''
      this.editQuest.data = {}
    },
    clickDeleteQuest(questType, questIndex) {
      const questions = this.items[questType]
      const curQuest = questions[questIndex]
      this.$bvModal.msgBoxConfirm(
          `Вы действительно хотите удалить вопрос "${ curQuest.question }"?`,
          this.modalOptions
      ).then(confirmed => {
        if (!confirmed) return

        api.delete(`/corpus/question/${ questType }/${ curQuest.id }`)
            .then(() => {
              questions.splice(questIndex, 1)

              this.$bvToast.toast('Вопрос успешно удален', {
                ...this.commonToaster, variant: 'success'
              })
            })
            .catch((error) => {
              console.log(error)
            })

      })
    },
    getQuestTitle(questType) {
      return `${ this.questionTypes[questType] } (${ this.items[questType].length })`
    },
    calcQuestionsToGeneratorQuiz() {
      let query = UrlMixin.encode({
        anchor: this.chunk.anchor,
        docId: this.$parent.selectedSource.document_id,
        questions: this.items.question_answer
      })
      return this.$router.resolve({
        name: 'GeneratorQuiz',
        query: {
          'qa': query
        }
      }).href
    },

    calcChunkToGenerator(questType) {
      let query = UrlMixin.encode({
        anchor: this.chunk.anchor,
        docId: this.$parent.selectedSource.document_id,
        chunkId: this.chunk.id,
        chunkText: this.chunk.text,
        chunkTitle: this.chunk.title
      })
      let routeName
      if (questType === 'quiz') {
        routeName = 'GeneratorQuiz'
      }
      else {
        routeName = 'GeneratorQA'
      }

      return this.$router.resolve({
        name: routeName,
        query: {
          chunk: query
        }
      }).href
    }
  },
  computed: {
    ...mapGetters(['HAS_PERMISSION', 'CHUNK_ICONS'])
  }
}
</script>

<template>
  <b-modal
      id="modal-show-quest"
      cancel-title="Закрыть" centered hide-footer lazy no-close-on-backdrop scrollable
      size="xl"
      static
      @close="clear"
      @show="onShow"
  >
    <template #modal-header="{close}">
      <div>
        <h4>{{ chunk.title }}</h4>
        <div v-html="markup(chunk.text)"/>
      </div>
      <b-button-close @click="close"/>
    </template>
    <b-card no-body>
      <b-tabs card>
        <b-tab
            v-for="questType in Object.keys(questionTypes)"
            v-if="items[questType]"
            :key="`tab-${questType}`"
            :title="getQuestTitle(questType)"
        >
          <template v-for="(quest, questIndex) in items[questType]">
            <span class="question ml-2 mb-2">

              <!--Карточка редактирования-->
              <b-card v-if="editQuest.data.id === quest.id && editQuest.type === questType" :header="`${editQuest.data.id ? 'Редактирование' : 'Создание'} вопроса`"
                      class="corpus-card">
                <b-input-group class="mb-2" title="Вопрос">
                  <b-input-group-prepend is-text>
                    <font-awesome-icon :icon="['far', 'circle-question']" fixed-width/>
                  </b-input-group-prepend>
                  <b-form-textarea v-model="editQuest.data.question" trim/>
                </b-input-group>

                <div class="ml-4">
                  <b-input-group title="Правильный ответ">
                    <b-input-group-prepend is-text>
                      <!--А-->
                      <font-awesome-icon :icon="['far', 'circle-check']" fixed-width/>
                    </b-input-group-prepend>
                    <b-form-textarea v-model="editQuest.data.answer" trim/>
                  </b-input-group>

                  <template v-for="(dis, disIndex) in editQuest.data.distractors" v-if="questType === 'quiz'">
                    <b-input-group :key="`key-edit-distractor-${disIndex}`" class="mt-2" title="Дистрактор">
                      <b-input-group-prepend is-text>
                        <p class="text-center" style="width: 20px">
                          {{ ['Б', 'В', 'Г'][disIndex] }}
                        </p>
                      </b-input-group-prepend>
                      <b-form-textarea v-model="editQuest.data.distractors[disIndex]" :title="dis" trim/>
                    </b-input-group>
                  </template>
                </div>
                <template #footer>
                  <b-btn variant="secondary" @click="clearQuestEdit">
                    Отменить
                  </b-btn>
                  <b-btn variant="success" @click="saveQuest(quest)">
                    Сохранить
                  </b-btn>
                </template>
              </b-card>

              <!--Карточка просмотра-->
              <b-card v-else :key="`quest-${questType}-${quest.id}`"
                      :class="{'corpus-item-done': quest.is_done}" class="corpus-card">
                <template #header>
                  <span>
                    {{ questIndex + 1 }}. {{ markup(quest.question) }}
                  </span>
                  <b-btn-group v-if="HAS_PERMISSION('corpus_quest_edit')" size="sm">
                    <l-btn
                        :title="quest.is_done ? 'Снять отметку завершенности' : 'Отметить завершенным'"
                        icon="check"
                        variant="outline-secondary"
                        @click="questDone(questType, quest, !quest.is_done)"
                    />
                    <l-btn
                        icon="pen"
                        title="Редактировать"
                        variant="outline-secondary"
                        @click="clickEditQuest(quest, questType)"
                    />
                    <l-btn
                        icon="trash"
                        title="Удалить"
                        variant="outline-secondary"
                        @click="clickDeleteQuest(questType, questIndex)"
                    />
                  </b-btn-group>
                </template>
                <div class="ml-4">

                  <template v-if="questType === 'quiz'">
                    <p v-html="`А. ${ markup(quest.answer)}`"/>
                    <p v-for="(distractor, disIndex) in quest.distractors"
                       v-html="`${['Б', 'В', 'Г'][disIndex]}. ${ markup(distractor)}`"/>
                  </template>

                  <template v-else>
                    <div v-html="markup(quest.answer)"/>
                  </template>
                </div>
                <template v-if="quest.when_is_done" #footer>
                  <small class="text-muted">
                    Проверено {{ formatDate(quest.when_is_done.date) }} пользователем
                    {{ quest.when_is_done.user.fullname }}
                  </small>
                </template>
              </b-card>
            </span>
          </template>

          <b-btn-group v-if="HAS_PERMISSION('corpus_quest_edit')" class="mt-3">
            <l-btn
                :label="questType === 'quiz' ? 'Создать тест' : 'Создать вопрос'"
                icon="square-plus"
                title="Создать новый вопрос"
                variant="outline-primary"
                @click="clickEditQuest({}, questType)"
            />

            <l-btn
                :href="calcChunkToGenerator(questType)"
                :label="`Генерировать ${questType === 'question_answer' ? 'вопросы' : 'тесты'}`"
                target="_blank"
                variant="outline-primary"
            />

            <l-btn
                v-if="questType === 'question_answer' && HAS_PERMISSION('generators')"
                :href="calcQuestionsToGeneratorQuiz()"
                label="Генерировать тесты из вопрос-ответов"
                target="_blank"
                variant="outline-primary"
            />

          </b-btn-group>

        </b-tab>
        <!--<b-tab title="Поиск тестов">
          <quiz-search/>
        </b-tab>-->
      </b-tabs>
    </b-card>
  </b-modal>
</template>

<style scoped>

</style>