<template>
  <div class="chunk-text">
    <font-awesome-icon
        v-if="CHUNK_ICONS[chunk.kind]"
        :icon="CHUNK_ICONS[chunk.kind].icon"
        :title="CHUNK_ICONS[chunk.kind].tooltip"
        fixed-width
        size="sm"
    />
    <span v-html="markup(chunk.text)"/>
    <br>
    <!--Ссылка чанка-->
    <a v-if="!hideHref && href" :href="href" target="_blank">
      {{ href }}
    </a>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import CorpusContentMixin from '@/mixins/CorpusContentMixin'

export default {
  name: 'chunk-text',
  mixins: [CorpusContentMixin],
  props: {
    chunk: {},
    source: {},
    hideHref: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters(['HAS_PERMISSION', 'CHUNK_ICONS', 'USER']),
    href() {
      if (this.source) {
        return this.calcChunkLink(this.chunk, this.source)
      }
    }
  }
}
</script>
<style scoped>

.btn-group-vertical > * {
  margin-bottom: 4px;
}

.btn-group > * {
  margin-right: 4px;
}
</style>