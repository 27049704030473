<script>
import DocumentMixin from '@/mixins/DocumentMixin'
import CardMixin from '@/mixins/CardMixin'
import SelectedChunks from '@/pages/Corpus/modals/SelectedChunks.vue'
import BtnGenerator from '@/components/BtnGenerator.vue'

export default {
  components: { BtnGenerator, SelectedChunks },
  props: {
    docId: String,
    selectedChunksObject: Object,
    selectedChunks: Array,
    corpus: Object,
    questionType: String,
    speciality: String,
    sections: Array,
    processingChunks: Array
  },
  mixins: [DocumentMixin, CardMixin],
  data() {
    return {
      params: {
        speciality: '',
        discipline: '',
        subDiscipline: ''
      }
    }
  },
  emits: ['unselect-excluded'],
  methods: {
    onShow() {
      this.documentParams.id = this.docId
      this.params.speciality = this.speciality
      this.params.discipline = this.corpus.name
      this.loadDocumentData()
    },
    clear() {
      this.params.speciality = ''
    },
    onOk() {
      this.$bvToast.toast(`Генерация запланирована`, {
        ...this.commonToaster, variant: 'success'
      })
      this.$bvModal.hide('modal-generator')
      this.$emit('on-ok')
    }
  },
  computed: {
    bgItems() {
      const res = {}
      for (const chunk of this.selectedChunks) {
        res[chunk.id] = {
          speciality: this.params.speciality,
          discipline: this.params.discipline,
          distractorTheme: chunk.title,
          documentName: this.documentParams.name,
          chunkText: chunk.text
        }
      }
      return res
    }

  }
}
</script>

<template>
  <b-modal
      id="modal-generator"
      :title="`Генерировать ${questionType === 'quiz' ? 'тесты' : 'вопросы-ответы'}`"
      cancel-title="Исключить исключенные"
      centered
      lazy
      no-close-on-backdrop
      ok-title="Генерировать"
      scrollable
      size="lg"
      static
      @close="clear"
      @show="onShow"
  >
    <template v-if="questionType === 'quiz'">
      <b-input-group class="mb-3">
        <b-input-group-prepend is-text>
          Специальность
        </b-input-group-prepend>

        <b-form-select v-model="params.speciality" :options="documentParams.specList"
                       :state="params.speciality > '' ? null : false"
                       text-field="name" value-field="name"/>
      </b-input-group>
      <b-input-group class="mb-3">
        <b-input-group-prepend is-text>
          Дисциплина
        </b-input-group-prepend>

        <b-form-input v-model="params.discipline" :state="params.discipline > '' ? null : false" trim/>
      </b-input-group>

      <b-input-group class="mb-3">
        <b-input-group-prepend is-text>
          Тема дистракторов
        </b-input-group-prepend>

        <b-form-input readonly value="Будет взята из заголовка чанка"/>
      </b-input-group>
    </template>
    <!--<template v-else>
      Запуск генерации для выбранных чанков
    </template>-->

    <selected-chunks :processing-chunks="processingChunks" :sections="sections"
                     :selected-chunks="selectedChunksObject"/>

    <template #modal-footer>
      <b-btn variant="outline-secondary" @click="$emit('unselect-excluded')">
        Исключить исключенные
      </b-btn>
      <btn-generator
          :bg-items="bgItems"
          :callback="onOk"
          :prompt-name="questionType"
          background
          btn-variant="outline-primary"
          list-processing
      />
    </template>
  </b-modal>

</template>

<style scoped>

</style>