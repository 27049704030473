import { render, staticRenderFns } from "./Chunks.vue?vue&type=template&id=15eb3dab&scoped=true"
import script from "./Chunks.vue?vue&type=script&lang=js"
export * from "./Chunks.vue?vue&type=script&lang=js"
import style0 from "./Chunks.vue?vue&type=style&index=0&id=15eb3dab&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "15eb3dab",
  null
  
)

export default component.exports