import { AiAlias, Prompt } from './main'

const systemContent = ``
const userContent = `Я дам тебе текст, тебе нужно перефразировать этот текст в более логически связанный текст.
Вот этот текст: "{text}".
Подумай и приступай.`
export default new Prompt({
  messages: {
    system: systemContent,
    user: userContent,
  },
  model: AiAlias.MINI,
})
