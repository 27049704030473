<script>

import corpusMixin from '@/mixins/CorpusMixin'

export default {
  name: 'CorpusWrapper',
  mixins: [corpusMixin],
  watch: {
    corpusId(value) {
      this.corpus.data = {}
      if (value) {
        this.loadCorpusById()
      }
    }
  },
  mounted() {
    if (this.corpusId) {
      this.loadCorpusById()
    }
    // this.$store.dispatch('CORPUS_LOAD_LIST').catch()
  }
}

</script>

<template>
  <b-container fluid class="p-0">
    <b-row no-gutters>
      <b-col cols="12">
        <b-navbar variant="light">
          <b-navbar-nav>
            <b-nav-item :to="{ name: 'CorpusRoot' }">
              <font-awesome-icon icon="bars"/>
            </b-nav-item>
            <template v-if="corpus.data.id">
              <b-nav-item :to="{ name: 'CorpusEdit', params: {corpusId} }" exact>Корпус</b-nav-item>
              <b-nav-item :to="{ name: 'CorpusSources', params: {corpusId} }">Источники</b-nav-item>
              <b-nav-item :to="{ name: 'CorpusChunks', params: {corpusId} }">Просмотр</b-nav-item>
              <b-nav-item
                  :to="{ name: 'ChunkEdit', params: {corpusId, chunkId: $route.params.chunkId} }"
                  v-if="$route.params.chunkId"
              >
                Редактирование чанка
              </b-nav-item>
            </template>
          </b-navbar-nav>
          <b-navbar-nav class="ml-auto" v-if="corpus.data.name">
            <b-navbar-brand class="ml-auto">
              {{ corpus.data.name }}, {{ corpus.data.year }} г.
            </b-navbar-brand>
            <!--<b-nav-item-dropdown :text="corpus.data.name || 'Выберите корпус для работы'" class="selected-corpus" right>
              <b-dropdown-item
                  v-for="item in $store.getters.CORPUS_LIST"
                  :key="'corpus-dd-' + item.id"
                  :to="{ name: $route.name !== 'CorpusRoot' ? $route.name: 'CorpusEdit', params: {corpusId: item.id} }">
                {{ item.name }}
              </b-dropdown-item>
            </b-nav-item-dropdown>-->
          </b-navbar-nav>
        </b-navbar>
        <router-view/>
      </b-col>
    </b-row>
  </b-container>
</template>

<style>
.corpus-sticky {
  top: 69px;
  position: sticky !important;
}
</style>

<style scoped>
.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, .7);
}

.navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover {
  color: rgba(0, 0, 0, .9);
}

.router-link-active {
  font-weight: bolder;
}

</style>

<style>
.selected-corpus {
  font-size: 1.25rem;
}

.selected-corpus .nav-link span {
  color: #00022e !important;
}
</style>