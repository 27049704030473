<script>
import chunkCreate from '@/ai/chunkCreate'
import ChunkText from '@/pages/Corpus/ChunkText.vue'
import BtnGenerator from '@/components/BtnGenerator.vue'
import CorpusContentMixin from '@/mixins/CorpusContentMixin'
import api from '@/api'

export default {
  components: { BtnGenerator, ChunkText },
  mixins: [CorpusContentMixin],
  data() {
    return {
      saveLoading: false,
      params: {
        info: this.section.title,
        smallListLength: 60, //'60 символов'
        chunkSize: '3 - 4'
      },
      editedIndex: undefined,
      result: [],
      prompt: chunkCreate
    }
  },
  props: {
    selectedChunks: Array[Object],
    source: Object,
    section: Object
  },
  emits: ['unselect-excluded'],
  methods: {
    onRequestComplete(response) {
      this.result = response.result
    },
    onSave() {
      if (this.result.length === 0) {
        this.$bvToast.toast(
            'Нет сгенерированных чанков', { ...this.commonToaster, variant: 'error' }
        )
        return
      }
      this.saveLoading = true
      const { id, ...data } = this.selectedChunks[0]
      api.post('/corpus/chunks/replacement', {
        old_chunk_list: this.selectedChunks.map((x) => x.id),
        new_chunk_list: this.result.map((x, index) => ({
          ...data,
          ...x,
          kind: 'generated',
          number: this.selectedChunks[0].number + index,
          is_excluded: false
        }))
      })
          .then(() => {
            this.$bvModal.hide('modal-generate-chunks')
            this.$emit('on-success')
            this.$emit('erase-selection')
            this.$emit('reload')
          })
          .finally(() => (this.saveLoading = false))
    }
  },
  computed: {
    chunkData() {
      return JSON.stringify(this.selectedChunks.map((x) => {
        // список ключей чанка, которые не передаем в запрос
        const { id, section_id, is_excluded, number, title, ...data } = x
        return data
      }), null, 2)
    }
  },
  filters: {}
}
</script>

<template>
  <b-modal
      id="modal-generate-chunks"
      cancel-title="Исключить исключенные"
      centered
      lazy
      modal-class="modal-fullscreen"
      no-close-on-backdrop
      scrollable
      static
      title="Генерация чанков"
  >
    <b-input-group class="mb-1">
      <b-input-group-prepend is-text>
        Атрибутивная информация
      </b-input-group-prepend>
      <b-textarea v-model="params.info"/>
    </b-input-group>

    <div class="d-flex w-100 mb-2 justify-content-between">
      <div class="d-flex justify-content-between">
        <div class="mr-3">
          <b-input-group style="width: 350px">
            <b-input-group-prepend is-text>
              Символов в коротком списке
            </b-input-group-prepend>
            <b-input
                v-model="params.smallListLength"
                type="number"
            />
          </b-input-group>
        </div>
        <div>
          <b-input-group style="width: 250px">
            <b-input-group-prepend is-text>
              Размер чанка
            </b-input-group-prepend>
            <b-input v-model="params.chunkSize"/>
          </b-input-group>
        </div>
      </div>
      <btn-generator
          :callback="(x) => onRequestComplete(x)"
          :params="{
            ...params,
            chunkData,
            smallListLengthTitlePlural: pluralize(params.smallListLength, 'символ', 'символа', 'символов')
          }"
          prompt-name="chunkCreate"
      />
    </div>

    <b-row>
      <b-col>
        <b-card title="Входящие чанки">

          <b-list-group-item
              v-for="chunk in selectedChunks"
              :class="{
                'excluded': chunk.is_excluded
              }"
              class="flex-column align-items-start mb-1"
          >
            <div class="d-flex w-100 justify-content-between title">

              <h5 class="mb-1">{{ chunk.title || 'Заголовок отсутствует' }}</h5>
              <!--<span v-if="chunk.is_excluded">[Искл.]</span>-->
            </div>

            <div>
              <chunk-text :chunk="chunk" :source="source"/>
            </div>

          </b-list-group-item>
        </b-card>

      </b-col>
      <b-col>
        <b-card title="Результат">
          <b-card
              v-for="(chunk, index) in result"
              :key="`out-${index}`"
              class="mt-2"
          >
            <div>
              <chunk-text :chunk="chunk" :source="source"/>
            </div>
            <!--<b-textarea
                v-if="index === editedIndex"
                :value="out"
                @update:model-value="(value) => (result[index] = value)"
            />
            <template v-else>{{ markup(out) }}</template>-->
            <!--<b-card-footer>
              <b-btn
                  :icon="index !== editedIndex ? icons.edit : icons.save"
                  @click="clickOutput(index)"
              />
            </b-card-footer>-->
          </b-card>
        </b-card>
      </b-col>
    </b-row>
    <template #modal-footer>
      <b-btn variant="secondary" @click="$emit('unselect-excluded')">
        Исключить исключенные
      </b-btn>

      <b-btn :disabled="result.length === 0 || saveLoading" variant="primary" @click="onSave">
        Сохранить
      </b-btn>
    </template>
  </b-modal>
</template>

<style>
/*.modal-full {
  max-width: 100%;
  height: 100vh !important;
}*/

.modal-fullscreen {
  width: 100%;
  height: 100%;
  margin: 0;
  max-width: 100%;
}

.modal-fullscreen .modal-dialog {
  height: 100%;
  max-width: 100%;
}

.modal-fullscreen .modal-content {
  height: 100%;
  max-width: 100%;
}
</style>