<script>
import api from '@/api'

const PROMPT_MAP = {
  quiz: 'Тесты',
  chunkCreate: 'Чанки',
  question_answer: 'Вопросы-ответы',
  heading: 'Заголовок',
  rephrase: 'Парафраз',
  distractors: 'Дистракторы',
  quizBasedQA: 'Тесты из вопрос-ответов',
  table2text: 'Таблицу в текст'
}
export default {
  name: "AiHistory",
  data() {
    return {

      filter: undefined,
      tableData: {
        headers: [
          {
            key: 'date',
            label: 'Дата',
            formatter: x => this.formatDate(x, 'dateTime'),
            sortable: true
          },
          {
            key: 'prompt_alias',
            label: 'Промпт',
            formatter: x => PROMPT_MAP[x] ?? x,
            sortable: true
          },
          {
            key: 'ai_model.code',
            label: 'Модель',
            sortable: true
          },
          {
            key: 'token_in',
            label: 'Токенов отправлено',
            sortable: true
          },
          {
            key: 'token_cached',
            label: 'Токенов кешировано',
            sortable: true
          },
          {
            key: 'token_out',
            label: 'Токенов получено',
            sortable: true
          },
          {
            key: 'price',
            label: 'Цена',
            formatter: x => `$${ x }`,
            sortable: true
          }
          // {
          //   key: 'requests',
          //   label: 'Всего запросов',
          //   formatter: (value) => value.length,
          //   sortable: true
          // },
          // {
          //   key: 'totals.price',
          //   label: 'Потрачено',
          //   sortable: true
          // }
        ],
        items: [],
        loading: true
      }
    }
  },
  mounted() {
    this.loadData()
  },
  computed: {
    userData() {
      const res = {}
      this.tableData.items.forEach(item => {
        const { user, ...data } = item
        if (!(user.id in res)) {
          res[user.id] = { ...user, requests: [], totals: { price: 0 } }
        }
        res[user.id].totals.price += data.price
        res[user.id].requests.push(data)
      })
      return res
    }
  },
  methods: {
    async loadData() {
      this.tableData.loading = true
      return api.get(`/generator/history`).then((response) => {
        if (response.status === 200) {
          this.tableData.items = response.data
        }
      }).finally(() => this.tableData.loading = false)
    }
  }
}
</script>

<template>
  <b-card title="История запросов">

    <b-card-text>
      <b-row>
        <b-button-toolbar class="button_toolbar">
          <b-button-group class="mx-1">
            <b-btn
                :disabled="tableData.loading"
                variant="outline-primary"
                @click="loadData"
            >
              <font-awesome-icon v-if="!tableData.loading" fixed-width icon="rotate-right"/>
              <b-spinner v-else small type="border"></b-spinner>
              Обновить
            </b-btn>
          </b-button-group>
        </b-button-toolbar>
      </b-row>


      <b-card v-for="item in userData" :key="`user-${item.id}`" no-body>
        <b-card-header style="display: flex; justify-content: space-between;">
          <div>
            <b-btn v-b-toggle="item.id" size="sm" variant="outline-primary">
              <font-awesome-icon icon="chevron-up" rotation="90"/>
            </b-btn>
            {{ item.fullname }} [{{ item.email }}]
          </div>
          <div>
            {{ pluralize(item.requests.length, "запрос", "запроса", "запросов") }}
          </div>
          <div>
            ${{ Math.round(item.totals.price * 1000) / 1000 }}
          </div>
        </b-card-header>
        <b-collapse :id="item.id" accordion="my-accordion" role="tabpanel" visible>
          <b-card-body>
            <b-table
                :fields="tableData.headers"
                :items="item.requests"
                head-variant="light"
                hover
                small
                sticky-header="200px"
                striped
            >
              <template #table-busy>
                <div class="text-center my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong>Данные загружаются...</strong>
                </div>
              </template>
            </b-table>
          </b-card-body>
        </b-collapse>
      </b-card>

      <!--<div class="float-right">
        <b-form-select v-model="tableData.options.perPage"
                       :options="tableData.options.pageOptions"
                       :select-size="1"
                       class="page-select">
        </b-form-select>
        <div class="page-per float-right">По:</div>
      </div>

      <b-pagination v-model="tableData.options.currentPage"
                    :per-page="tableData.options.perPage"
                    :total-rows="tableData.items.length"
                    class="float-right mx-0 my-0"></b-pagination>
      <div class="page-total float-right">Всего: {{ tableData.items.length }}</div>
      <b-input-group class="float-right w-25 mr-2">
        <b-form-input v-model="filter" placeholder="Поиск по таблице"></b-form-input>
        <b-input-group-append>
          <b-btn :disabled="!filter" class="page-filter-clear" @click="filter = undefined">
            <font-awesome-icon icon="eraser"/>
          </b-btn>
        </b-input-group-append>
      </b-input-group>

      <b-table
          :busy.sync="tableData.loading"
          :current-page="tableData.options.currentPage"
          :fields="tableData.headers"
          :filter="filter"
          :items="tableData.items"
          :per-page="tableData.options.perPage"
          :sort-by.sync="tableData.options.sortBy"
          :sort-desc.sync="tableData.options.sortDesc"
          hover
          responsive
          show-empty
          small
          striped
      >
        <template #table-busy>
          <div class="text-center my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Данные загружаются...</strong>
          </div>
        </template>
      </b-table>-->
    </b-card-text>
  </b-card>
</template>

<style scoped>

</style>